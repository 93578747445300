import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../img/logo.svg';
import { FiMenu } from 'react-icons/fi';
import '../fonts/fonts.css';
import routes from '../routes';

const NavBar: React.FC = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <Container>
      <Link to={routes.home}>
        <Logo src={logo} />
      </Link>
      <Nav>
        <NavLink to={routes.home}>Home</NavLink>
        <NavLink to={routes.about}>Über mich</NavLink>
        <NavLink to={routes.concept}>Konzept</NavLink>
        <NavLink to={routes.qualification}>Qualifizierung</NavLink>
        <NavLink to={routes.contact}>Kontakt</NavLink>
      </Nav>
      <MobileNav>
        <MenuIcon onClick={() => setIsMobileNavOpen(!isMobileNavOpen)} />
        {isMobileNavOpen && (
          <DropdownMenu>
            <NavLink to={routes.home}>Home</NavLink>
            <NavLink to={routes.about}>Über mich</NavLink>
            <NavLink to={routes.concept}>Konzept</NavLink>
            <NavLink to={routes.qualification}>Qualifizierung</NavLink>
            <NavLink to={routes.contact}>Kontakt</NavLink>
          </DropdownMenu>
        )}
      </MobileNav>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1200px) {
    height: 160px;
    padding: 20px;
  }
`;

const Logo = styled.img`
  height: 80px;

  @media (min-width: 1200px) {
    height: 120px;
  }
`;

const Nav = styled.div`
  margin-left: auto;
  display: none;
  flex-direction: row;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  position: absolute;
  right: 0;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  top: 50px;

  a {
    padding: 12px 20px;
  }
`;

const MenuIcon = styled(FiMenu)`
  font-size: 30px;
  color: #6296cf;
`;

const MobileNav = styled.div`
  position: relative;
  display: flex;
  margin-left: auto;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  padding: 40px 30px;
  font-size: 20px;
  color: #6296cf;
  text-decoration: none;
  font-family: 'Berlin Sans FB', sans-serif;

  &:hover {
    background-color: #8fb2de;
    color: white;
  }
`;

export default NavBar;
