const routes = {
  home: '/',
  about: '/ueber-mich',
  concept: '/konzept',
  qualification: '/qualifizierung',
  contact: '/kontakt',
  privacy: '/datenschutz',
  roomDesign: '/raumgestaltung',
  location: '/ort',
  imprint: '/impressum',
  familarisation: '/eingewoehnungsphase',
  toiletTraining: '/sauberkeitserziehung',
  schedule: '/tagesablauf',
};

export default routes;
